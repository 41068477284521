export class Weather {
  time?: string
  last_battery?: number
  last_celsius?: number
  last_humidity?: number
  last_pressure?: number
  localtime?: string
  wind_speed?: number
  wind_dir?: string
  gust?: number
  moon?: number
  sunrise?: string
  sunset?: string
  air?: number
  icon?: string
  icon_url?: string
  day1_icon_url?: string
  day2_icon_url?: string
  day3_icon_url?: string
  is_day?: number
  icon_index?: number
  day?: string
  day1_day?: string
  day1_temp?: number
  day1_condition?: string
  day1_icon?: string
  day1_icon_index?: number
  day2_day?: string
  day2_temp?: number
  day2_condition?: string
  day2_icon?: string
  day2_icon_index?: number
  day3_day?: string
  day3_temp?: number
  day3_condition?: string
  day3_icon?: string
  day3_icon_index?: number
}


