import {Component, OnInit} from '@angular/core';
import {WeatherService} from "./weather.service";
import {Weather} from "./weather";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'weather-card';

  weather!: Weather;
  constructor(private weatherService: WeatherService,) { }

  ngOnInit() {
    this.weather = new Weather();

    this.weatherService.getWeather().subscribe(data => {
      this.weather = data;
    })
  }
}
