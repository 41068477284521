<!-- component -->
<div class="w-full md:mt-16 lg:mt-64 lg:px-40 justify-center container mx-auto">
  <div class="flex flex-wrap w-full lg:w-auto">
    <div class="w-full lg:w-1/2 flex sm:rounded-t-lg lg:rounded-lg bg-auto" style="background-image: url('https://images.unsplash.com/photo-1559963110-71b394e7494d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80')">
      <div class="sm:rounded-t-lg lg:rounded-lg py-6 pl-8 pr-32 w-full bg-blue-400 opacity-90 text-white">
        <div class="mb-20">
          <h2 class="font-bold text-3xl leading-none pb-1">Dzisiaj</h2>
          <h3 class="leading-none pb-2 pl-1">{{ weather.localtime}}</h3>
        </div>
        <div>
          <img alt="{{ weather.day }}" src="{{ weather.icon_url }}"/>
          <strong class="leading-none text-6xl block font-weight-bolder">{{ weather.last_celsius }}ºC</strong>
          <b class="text-2xl block font-bold">Aktualizacja: {{ weather.time}}</b>
        </div>
      </div>
    </div>

    <div class="w-full lg:w-1/2 flex ml-0">
      <div class="lg:my-3 bg-gray-800 text-white p-8 lg:rounded-r-lg w-full">
        <div class="flex justify-between w-64 mb-4 w-full">
          <div class="w-auto font-bold uppercase text-90">Ciśnienie</div><div class="w-auto text-right">{{ weather.last_pressure}} hPa</div>
        </div>
        <div class="flex justify-between w-64 mb-4 w-full">
          <div class="w-auto font-bold uppercase text-90">Wilgotność</div><div class="w-auto text-right">{{ weather.last_humidity}} %</div>
        </div>
        <div class="flex justify-between w-64 mb-8 w-full">
          <div class="w-auto font-bold uppercase text-90">Wiatr</div><div class="w-auto text-right">{{ weather.wind_dir}} {{ weather.wind_speed}} ({{ weather.gust}}) kmh</div>
        </div>
        <div class="flex justify-between w-64 mb-8 w-full">
          <div class="w-auto font-bold uppercase text-90">Księżyc</div><div class="w-auto text-right">{{ weather.moon}} %</div>
        </div>
        <div class="flex justify-between w-64 mb-8 w-full">
          <div class="w-auto font-bold uppercase text-90">Wschód / Zachód</div><div class="w-auto text-right">{{ weather.sunrise}} / {{ weather.sunset}}</div>
        </div>
        <div class="flex flex-row">
          <div class="flex flex-col w-1/3 bg-gray-100 text-black rounded-lg pb-4">
            <div class="flex justify-center items-center pt-2 mb-2">
              <img alt="{{ weather.day }}" src="{{ weather.day1_icon_url }}"/>
            </div>
            <div class="text-center">
              <b class="font-normal">{{ weather.day1_day}}</b><br>
              <strong class="text-xl">{{ weather.day1_temp}}ºC</strong>
            </div>
          </div>

          <div class="flex flex-col w-1/3 bg-gray-900 rounded-lg">
            <div class="flex justify-center items-center pt-2 mb-2">
              <img alt="{{ weather.day }}" src="{{ weather.day2_icon_url }}"/>
            </div>
            <div class="text-center">
              <b class="font-normal">{{ weather.day2_day}}</b><br>
              <strong class="text-xl">{{ weather.day2_temp}}ºC</strong>
            </div>
          </div>
          <div class="flex flex-col w-1/3 bg-gray-900 rounded-lg">
            <div class="flex justify-center items-center pt-2 mb-2">
              <img alt="{{ weather.day }}" src="{{ weather.day3_icon_url }}"/>
            </div>
            <div class="text-center">
              <b class="font-normal">{{ weather.day3_day}}</b><br>
              <strong class="text-xl">{{ weather.day3_temp}}ºC</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
